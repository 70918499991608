const URL_PARAMS = {
  globalSortingOption: 'sorting',
  globalFilterOption: 'filter',
  globalTypeOption: 'type',
  textSearch: 'query',
  sortId: 'sortId',
  sorting: 'sortDirection',
  startDateRange: 'dateStart',
  endDateRange: 'dateEnd',
  displayElementsNumber: 'rows',
  pageIndex: 'pageIndex',
  filterCategory: 'filterCategory',
  filterAspectRatio: 'filterAspectRatio',
  filterAssetType: 'filterAssetType',
  filterType: 'filterType',
  filterTenant: 'filterTenant',
};

export default URL_PARAMS;
