import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { Button, SelectFieldv2 } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileImport,
  faPlus,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import { debounce } from 'shared/helpers/debounce';

import './TopBar.scss';
import { SingleValue } from 'react-select';
import getGlobalFilterOptionURLParam from 'components/v2/DataTable/helpers/getGlobalFilterOptionURLParam';
import setURLParams from 'components/v2/DataTable/helpers/setURLParams';
import getURLParam from 'components/v2/DataTable/helpers/getURLParam';
import URL_PARAMS from 'components/v2/DataTable/data/urlParams';
import type { SelectFieldOptionObject } from '../SelectField/types';

const DEFAULT_SORTING_VALUE = 'internalTitle';

const onChange = (
  setValue: (value: string) => void,
  event: SingleValue<SelectFieldOptionObject>
): void => {
  if (event) {
    const { value } = event;
    setValue(value);
  }
};

interface SearchProps {
  searchQuery?: string;
  setSearchQuery: (query: string) => void;
  tableId: string;
  isDisabled?: boolean;
}

export const Search: React.FC<SearchProps> = ({
  searchQuery = '',
  setSearchQuery,
  tableId,
  isDisabled,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSearchQuery(event.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(handleChange), []);

  return (
    <Col sm={6} md={6} lg={3}>
      <form
        className="form search-bar"
        onSubmit={event => {
          event.preventDefault();
        }}
      >
        <div className="form__form-group-field">
          <Label className="sr-only" htmlFor="tableSearch">
            Search
          </Label>
          <Input
            className="search-input"
            id="tableSearch"
            name="tableSearch"
            placeholder="Search"
            type="search"
            defaultValue={searchQuery}
            onChange={event => {
              event.persist();
              debouncedChangeHandler(event);
            }}
            data-testid={`${tableId}__searchInput`}
            disabled={isDisabled}
          />
          <Button
            aria-label="Search"
            type="button"
            className="input-group-append"
            color="secondary"
            icon={<FontAwesomeIcon icon={faSearch} />}
            disabled={isDisabled}
          />
        </div>
      </form>
    </Col>
  );
};

interface Filter {
  label: string;
  value: string;
}

interface StatusFiltersProps {
  filters: Array<Filter>;
  setFilteredOption: (value: string) => void;
  filteredOption?: string;
  filterName?: string;
  tableId: string;
  isDisabled?: boolean;
}

export const StatusFilters: React.FC<StatusFiltersProps> = ({
  filters,
  setFilteredOption,
  filterName = '',
  tableId,
  isDisabled,
}) => {
  const filterUrlParamKey = getGlobalFilterOptionURLParam(filterName);
  const onSelectFieldChange = useCallback(
    (newValue: SingleValue<SelectFieldOptionObject>) => {
      const filter = newValue?.value;
      if (filter)
        setURLParams({
          [filterUrlParamKey]: filter,
        });
      onChange(setFilteredOption, newValue);
    },
    [filterUrlParamKey, setFilteredOption]
  );

  const defaultValue = getURLParam(filterUrlParamKey);

  return (
    <Col xs="auto" className="labeled-toggle-container">
      <SelectFieldv2
        onChange={onSelectFieldChange}
        id={filterName}
        isClearable={false}
        isSearchable={false}
        options={filters}
        selectFieldId={tableId}
        defaultValue={defaultValue || 'all'}
        isMulti={false}
        isDisabled={isDisabled}
      />
    </Col>
  );
};

interface Option {
  label: string;
  value: string;
}

interface SortingOptionsProps {
  options: Array<Option>;
  setSortingOption: Dispatch<SetStateAction<string>>;
  defaultSortingOption?: string;
  sortingOption: string;
  isDisabled?: boolean;
}

export const SortingOptions: React.FC<SortingOptionsProps> = ({
  options,
  setSortingOption,
  sortingOption,
  defaultSortingOption = DEFAULT_SORTING_VALUE,
  isDisabled,
}) => {
  const onSelectFieldChange = useCallback(
    (event: SingleValue<SelectFieldOptionObject>) => {
      onChange(setSortingOption, event);
    },
    [setSortingOption]
  );

  useEffect(() => {
    setURLParams({
      [URL_PARAMS.globalSortingOption]: sortingOption || defaultSortingOption,
    });
  }, [defaultSortingOption, sortingOption]);

  return (
    <Col xs="auto" className="labeled-toggle-container">
      <span className="sortingLabel">Sort by:</span>
      <div className="sortingOptionsContainer">
        <SelectFieldv2
          onChange={onSelectFieldChange}
          id="sorting"
          isClearable={false}
          isSearchable={false}
          options={options}
          defaultValue={sortingOption || DEFAULT_SORTING_VALUE}
          isMulti={false}
          isDisabled={isDisabled}
        />
      </div>
    </Col>
  );
};

export interface SearchQueryProps {
  searchQuery?: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

export interface TopBarConfig {
  searchFeature?: SearchQueryProps;
  sortingFeature?: {
    sortingOption: string;
    defaultSortingOption?: string;
    setSortingOption: Dispatch<SetStateAction<string>>;
    options: Array<Option>;
  };
  filterFeature?: Array<{
    filters: Array<Filter>;
    filteredOption?: string;
    defaultFilterOption?: string;
    setFilteredOption: Dispatch<SetStateAction<string>>;
    filterName: string;
  }>;
  customButtons?: React.ReactNode;
  addButton?: {
    addButtonText: string;
    onAddButtonClick: () => void;
    id?: string;
    dataTestId?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
  };
  otherButton?: {
    buttonText: string;
    onButtonClick: () => void;
  };
}

export interface TopBarProps {
  topbarConfig: TopBarConfig;
  pageSize: number; // Needed for top Pagination Info, e.g. 21 to 40 of 100
  pageIndex: number; // Needed for top Pagination Info, e.g. 21 to 40 of 100
  totalElementsCount: number; // Needed for top Pagination Info, e.g. 21 to 40 of 100
  tableId: string; // e.g. for testing purposes
}

const TopBar: React.FC<TopBarProps> = ({
  pageSize,
  pageIndex,
  totalElementsCount,
  tableId,
  topbarConfig: {
    searchFeature,
    filterFeature,
    sortingFeature,
    customButtons,
    addButton,
    otherButton,
  },
}) => {
  const pagesFrom = totalElementsCount === 0 ? 0 : 1 + pageSize * pageIndex;
  const pagesUntil =
    pageSize * (pageIndex + 1) > totalElementsCount
      ? totalElementsCount
      : pageSize * (pageIndex + 1);

  const addButtonTestId = addButton?.dataTestId
    ? {
        'data-testid': addButton.dataTestId,
      }
    : {};

  return (
    <Row className="topbar">
      {searchFeature && (
        <Search
          searchQuery={searchFeature.searchQuery}
          setSearchQuery={searchFeature.setSearchQuery}
          tableId={tableId}
        />
      )}
      {sortingFeature && (
        <SortingOptions
          sortingOption={sortingFeature?.sortingOption}
          options={sortingFeature.options}
          defaultSortingOption={sortingFeature.defaultSortingOption}
          setSortingOption={sortingFeature.setSortingOption}
        />
      )}
      {filterFeature &&
        filterFeature.map(filter => (
          <StatusFilters
            key={filter.filterName}
            filters={filter.filters}
            filteredOption={filter.filteredOption}
            setFilteredOption={filter.setFilteredOption}
            filterName={filter.filterName}
            tableId={tableId}
          />
        ))}

      <span data-testid={`${tableId}__pagesInfo`}>
        Showing {pagesFrom} to {pagesUntil} of {totalElementsCount}
      </span>
      <div className="buttons-section">
        {customButtons || (
          <>
            {otherButton && (
              <button
                onClick={otherButton.onButtonClick}
                type="button"
                className="btn btn-outline-primary"
              >
                <FontAwesomeIcon icon={faFileImport} /> {otherButton.buttonText}
              </button>
            )}
            {addButton && (
              <button
                onClick={addButton.onAddButtonClick}
                type="button"
                className="btn btn-primary btn-create new"
                disabled={addButton.isDisabled}
                {...addButtonTestId}
              >
                <FontAwesomeIcon icon={faPlus} /> {addButton.addButtonText}
              </button>
            )}
          </>
        )}
      </div>
    </Row>
  );
};

export default TopBar;
