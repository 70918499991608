type ParamKeyValuePairs = Record<string, string>;

const setURLParams = (paramNames: ParamKeyValuePairs): string => {
  const url = new URL(window.location.href);
  const params = Object.entries(paramNames);
  params.forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  const urlHasToChange = url.toString() !== window.location.href;
  if (urlHasToChange) window.history.replaceState(null, '', url);
  return url.toString();
};

export default setURLParams;
