import React from 'react';
import './PieChartTooltipV2.scss';

interface PieChartTooltipV2Props {
  tooltipData?: {
    name: string;
    fill: string;
    value: string | number;
    percent: string | number;
  };
}

const PieChartTooltipV2: React.FC<PieChartTooltipV2Props> = ({
  tooltipData,
}) => {
  if (tooltipData) {
    const { name, fill, value, percent } = tooltipData;
    return (
      <div className="answer-data label">
        <div className="label-top">
          <span className="dot" style={{ backgroundColor: fill }} />
          <b>{name}</b>
        </div>
        <div className="label-bottom">
          {percent}% <span>({Number(value).toLocaleString()})</span>
        </div>
      </div>
    );
  }

  return null;
};

export default PieChartTooltipV2;
