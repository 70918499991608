import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import { UncontrolledPopover, UncontrolledPopoverProps } from 'reactstrap';
import { Button } from 'components';
import './Popover.scss';

export interface PopoverProps
  extends Pick<UncontrolledPopoverProps, 'placement'> {
  /** className for the trigger button */
  buttonClassName?: string;
  /** Unique ID required to link the popover to the trigger */
  id: string;
  /* Boolean used to update the zIndex of popovers inside modals */
  inModal?: boolean;
  /** Button/trigger contents */
  trigger: JSX.Element;
  popoverTriggerMethod?: 'focus' | 'click' | 'legacy';
}

/** Popover for showing additional info on click. Required for the color picker. */

const Popover: React.FC<PropsWithChildren<PopoverProps>> = props => {
  const {
    buttonClassName,
    children,
    id,
    inModal,
    placement = 'auto',
    trigger,
    popoverTriggerMethod = 'focus',
  } = props;

  return (
    <>
      <Button
        className={cx('popover-btn', buttonClassName)}
        id={id}
        tabIndex={-1}
        onClick={event => event.currentTarget.focus()}
      >
        {trigger}
      </Button>
      <UncontrolledPopover
        placement={placement}
        target={id}
        trigger={popoverTriggerMethod}
        className={inModal ? 'modalPopover' : ''}
      >
        {children}
      </UncontrolledPopover>
    </>
  );
};

export default Popover;
