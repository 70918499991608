import React, { useRef, useState, type CSSProperties, useEffect } from 'react';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faUndo } from '@fortawesome/pro-solid-svg-icons';

import './TimestampPicker.scss';

/**
 * A timestamp picker with a video preview
 * And frame-by-frame controls
 */

interface TimestampPickerProps {
  videoUrl: string;
  selectedTimestamp: number;
  setSelectedTimestamp: (newTimestamp: number) => void;
  autoPlay: boolean;
  setVideoStill: (image: Blob) => void;
}

type PlayState = 'initial' | 'playing' | 'paused' | 'ended';

const videoRenderStill = (
  element: HTMLVideoElement,
  successCallback: (image: Blob) => void
) => {
  const canvas = document.createElement('canvas');
  canvas.width = element.videoWidth;
  canvas.height = element.videoHeight;
  canvas
    .getContext('2d')
    ?.drawImage(element, 0, 0, canvas.width, canvas.height);

  canvas.toBlob(
    blob => {
      if (blob) {
        successCallback(blob);
      } else {
        throw new Error('Failed to extract video still.');
      }
    },
    'image/jpeg',
    1.0
  );
};

const TimestampPicker = ({
  videoUrl,
  selectedTimestamp,
  setSelectedTimestamp,
  autoPlay,
  setVideoStill,
}: TimestampPickerProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [playState, setPlayState] = useState<PlayState>('initial');

  useEffect(() => {
    if (!videoRef.current) return () => {}; // Shouldn't happen; check required for type safety.
    const videoElement = videoRef.current; // Store now for use during cleanup.

    // Set the initial video timestamp. This is particularly useful if this picker is reopened.
    videoElement.currentTime = selectedTimestamp;

    // Render a screenshot. HTML5 doesn't (as of October 2023) do frame accurate video. So it is
    // safer to use the video element as the source of truth. This is instead of trying to use
    // the timestamp to, separately and later, extract the exact same frame.
    return () => videoRenderStill(videoElement, setVideoStill);

    // We only do the above once. It would be painfully slow (and pointless!) to do these actions
    // every time the actual video timestamp changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playVideo = () => {
    videoRef.current?.play();
    setPlayState('playing');
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setPlayState('paused');
      setSelectedTimestamp(videoRef.current.currentTime);
    }
  };

  const replayVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setPlayState('playing');
    }
  };

  const setTimestamp = (time: number) => {
    if (videoRef.current) {
      videoRef.current.pause();
      setPlayState('paused');
      videoRef.current.currentTime = time;
      setSelectedTimestamp(time);
    }
  };

  const FRAME_RATE = 1 / 30; // This is a guess and approximation, but it's probably close enough for real world usage.
  const goToPreviousFrame = () => {
    if (!videoRef.current) return;

    videoRef.current.pause();

    const canGoToPreviousFrame = videoRef.current.currentTime - FRAME_RATE > 0;

    if (canGoToPreviousFrame) {
      videoRef.current.currentTime -= FRAME_RATE;
      setPlayState('paused');
    } else {
      videoRef.current.currentTime = 0;
      setPlayState('initial');
    }
  };

  const goToNextFrame = () => {
    if (!videoRef.current) return;

    videoRef.current.pause();

    const canGoToNextFrame =
      videoRef.current.currentTime + FRAME_RATE < videoDuration;

    if (canGoToNextFrame) {
      videoRef.current.currentTime += FRAME_RATE;
      setPlayState('paused');
    } else {
      videoRef.current.currentTime = videoDuration;
      setPlayState('ended');
    }
  };

  const mainAction = {
    initial: { icon: faPlay, title: 'Play', onClick: playVideo },
    playing: { icon: faPause, title: 'Pause', onClick: pauseVideo },
    paused: { icon: faPlay, title: 'Play', onClick: playVideo },
    ended: { icon: faUndo, title: 'Replay', onClick: replayVideo },
  };

  const onVideoLoadedMetadata = () => {
    if (videoRef.current && !Number.isNaN(videoRef.current.duration)) {
      setVideoDuration(videoRef.current.duration);
    }
  };

  return (
    <figure className="timestamp-picker">
      <video
        autoPlay={autoPlay}
        controls={false}
        crossOrigin="anonymous" // Required for canvas.toBlob()
        muted
        playsInline
        preload="auto"
        ref={videoRef}
        onLoadedMetadata={onVideoLoadedMetadata}
        onTimeUpdate={e => setSelectedTimestamp(e.currentTarget.currentTime)}
        onPlay={() => setPlayState('playing')}
        onPause={() => setPlayState('paused')}
        onEnded={() => setPlayState('ended')}
      >
        <source src={videoUrl} />
      </video>
      <figcaption className="timestamp-picker-controls">
        <div className="timestamp-picker-control-actions">
          <Button
            className="timestamp-picker-control"
            color="info"
            icon={<FontAwesomeIcon icon={mainAction[playState].icon} />}
            onClick={mainAction[playState].onClick}
            title={mainAction[playState].title}
          />
          <Button
            className="timestamp-picker-control"
            color="info"
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            icon={<PreviousFrameIcon />}
            onClick={() => goToPreviousFrame()}
            title="One frame backwards"
            disabled={selectedTimestamp === 0}
          />
          <Button
            className="timestamp-picker-control"
            color="info"
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            icon={<NextFrameIcon />}
            onClick={() => goToNextFrame()}
            title="One frame forwards"
            disabled={playState === 'ended'}
          />
        </div>
        {videoRef.current && videoDuration > 0 && (
          <input
            type="range"
            min="0"
            max={videoDuration}
            step={FRAME_RATE}
            value={selectedTimestamp}
            onChange={e => setTimestamp(e.target.valueAsNumber)}
            className="timestamp-picker-progress"
            style={
              {
                '--progress': `${(selectedTimestamp * 100) / videoDuration}%`,
              } as CSSProperties
            }
          />
        )}
      </figcaption>
    </figure>
  );
};

/* These icons don't come from FontAwesome
and they're only used in this component. */
function PreviousFrameIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      fill="none"
      viewBox="0 0 22 18"
    >
      <path
        fill="currentColor"
        d="M.727 7.706a1.52 1.52 0 000 2.594l11 6.5c1 .594 2.25-.125 2.25-1.313v-13c0-1.28-1.344-1.812-2.25-1.28l-11 6.5zM22 15.488a1.5 1.5 0 01-1.5 1.5h-3a1.48 1.48 0 01-1.5-1.5v-13a1.5 1.5 0 011.5-1.5h3c.813 0 1.5.687 1.5 1.5v13z"
      />
    </svg>
  );
}

function NextFrameIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      fill="none"
      viewBox="0 0 22 18"
    >
      <path
        fill="currentColor"
        d="M21.262 7.706c.968.594.968 2 0 2.594l-11 6.5c-1 .594-2.25-.125-2.25-1.313v-13c0-1.28 1.343-1.812 2.25-1.28l11 6.5zM6.012 15.488a1.5 1.5 0 01-1.5 1.5h-3a1.48 1.48 0 01-1.5-1.5v-13a1.5 1.5 0 011.5-1.5h3c.812 0 1.5.687 1.5 1.5v13z"
      />
    </svg>
  );
}

export default TimestampPicker;
