import React from 'react';
import classnames from 'classnames';
import { colord } from 'colord';
import type { ChartDataPoint } from '../types';

import './ChartTooltip.scss';

type TooltipData = {
  nearestDatum?: {
    distance: number;
    index: number;
    key: string;
    datum: ChartDataPoint['values'];
  };
};

export interface ChartTooltipV2Props {
  /** Function to return more human-readable data. */
  formatter?: (data: string) => string;
  /** Payload provided by recharts, with prop checking for the values we use */
  tooltipData?: TooltipData;
  colorScale?: (dataKey: string) => string;
  /** Add units to toolips */
  yUnit?: string;
}

const identity = <T extends unknown>(x: T) => x;

/** A custom tooltip for area/bar/line charts. Shows only the nearest series. */

const ChartTooltipV2: React.FC<ChartTooltipV2Props> = ({
  formatter = identity,
  tooltipData,
  colorScale,
  yUnit,
}) => {
  const x = tooltipData?.nearestDatum?.key;
  const y = x && tooltipData?.nearestDatum?.datum[x];
  const stroke = x && colorScale?.(x);

  if (x === undefined || y === undefined || !stroke) {
    return null;
  }

  const color = colord(stroke).alpha(0.1).toHex();

  return (
    <div
      className={classnames('chart-tooltip')}
      style={{
        borderColor: color,
      }}
    >
      <div
        className="header"
        style={{
          backgroundColor: color,
        }}
      >
        <h3>
          {tooltipData?.nearestDatum?.datum?.name
            ? formatter(tooltipData.nearestDatum.datum.name)
            : null}
        </h3>
      </div>
      <div className="info">
        <h4
          style={{
            color: stroke,
          }}
        >
          {new Intl.NumberFormat().format(y as number)}
          {yUnit}
        </h4>
        <small>{x}</small>
      </div>
    </div>
  );
};

export default ChartTooltipV2;

export const renderTooltip = (tooltipProps: ChartTooltipV2Props) => (
  <ChartTooltipV2 {...tooltipProps} />
);
export type RenderTooltip = typeof renderTooltip;
