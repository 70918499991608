import React, {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import * as signalR from '@microsoft/signalr';
import signalEvent from 'infrastructure/signalR/signalEvents';
import { useConfig } from 'infrastructure/config';
import { getAccessToken } from 'infrastructure/security/accessTokenManager';

export interface ISignalRContext {
  connection: MutableRefObject<signalR.HubConnection | undefined>;
  listenForSignalEvent: (
    eventName: SignalEventName,
    callback: SignalEventCallback
  ) => void;
  stopListeningForTheSignalEvent: (eventName: SignalEventName) => void;
  signalEvent: Record<string, string>;
}

export const SignalRContext = createContext<ISignalRContext>(
  {} as ISignalRContext
);

export type SignalEventName = string;
export type SignalEventCallback = () => void;

const SignalRContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const connection = useRef<signalR.HubConnection>();
  const config = useConfig();
  const { apiUrl, signalRHubName } = config;
  const signalRLogLevel = config.signalRLogLevel || signalR.LogLevel.Error;

  /**
   * This public method is binding the .on() callback on SignalR's connection
   * object. Docs ref: https://docs.microsoft.com/pl-pl/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0#call-client-methods-from-the-hub
   * @param eventName
   * @param callback
   */
  const listenForSignalEvent = (
    eventName: SignalEventName,
    callback: SignalEventCallback
  ) => {
    if (!connection.current) {
      return;
    }
    connection.current.on(eventName, callback);
  };

  /**
   * Undocumented method of SignalR but exists in source code.
   * GitHub ref: https://github.com/SignalR/SignalR/blob/f0a1b5bdb891601e730a7d0ea70cd85d7a09e527/src/Microsoft.AspNet.SignalR.JS/jquery.signalR.hubs.js#L158
   * @param eventName
   */
  const stopListeningForTheSignalEvent = (eventName: SignalEventName) => {
    if (!connection.current) {
      return;
    }
    connection.current.off(eventName);
  };

  /**
   * This effect is responsible for creating SignalR Hub object, establishing a connection and binding all of the
   * callbacks being used in application.
   */
  useEffect(() => {
    // Do not create another connection when e.g. hot-reload happens or basically there is another connection established
    if (connection.current) {
      return;
    }
    const finalURL = new URL(signalRHubName, apiUrl).href;

    const token = getAccessToken() || '';

    connection.current = new signalR.HubConnectionBuilder()
      .withUrl(finalURL, { accessTokenFactory: () => token })
      .withAutomaticReconnect()
      .configureLogging(signalRLogLevel)
      .build();

    const onConnected = () => {
      connection.current?.invoke('AddToGroup');
    };

    connection.current
      .start()
      .then(() => {
        onConnected();
      })
      .catch(error => {
        console.error(error.message);
      });

    connection.current.onreconnected(() => {
      onConnected();
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      connection,
      listenForSignalEvent,
      stopListeningForTheSignalEvent,
      signalEvent,
    }),
    []
  );

  return (
    <SignalRContext.Provider value={contextValue}>
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRContextProvider;
