import React from 'react';
import { PieChartData } from './PieChartV2';

import './PieChartLegendV2.scss';

interface PieChartLegendV2Props {
  dataItems: Array<PieChartData & { percent: number }>;
}

const PieChartLegendV2: React.FC<PieChartLegendV2Props> = ({ dataItems }) => (
  <div className="legend-rows">
    {dataItems.map(dataItem => {
      const { name, value, percent, imageUrl, fill } = dataItem;

      return (
        <div className="legend-single-row" key={`${value}-${name}`}>
          {imageUrl && (
            <img
              src={imageUrl}
              alt={`${name}`}
              width="32"
              height="40"
              className="rounded"
            />
          )}
          <div
            className="legend-color-indicator"
            style={{ backgroundColor: fill }}
          />
          <div className="legend-label">
            {name}
            <b>{`${percent}%`}</b>
            <small className="text-muted font-weight-light value-total">
              ({value.toLocaleString()})
            </small>
          </div>
        </div>
      );
    })}
  </div>
);

export default PieChartLegendV2;
